import React from 'react';
import Seo from '../components/config/Seo';
import Faq from '../components/shared/sections/faq-section';
import FormSection from '../components/shared/sections/form-section';
import { graphql } from 'gatsby';
import useGetApplyTranslation from '../hooks/useGetApplyTranslation';

const Apply = ({ data }) => {
  const {
    pageTitle,
    formSection,
    faq,
  } = useGetApplyTranslation();

  return (
    <>
      <Seo title={pageTitle} />
      <FormSection
        title={formSection.title}
        descriptions={[...formSection.descriptions]}
        successMessage={{
          title: formSection.successMessage.title,
          message: formSection.successMessage.message,
          buttonText: formSection.successMessage.buttonText,
        }}
      />
      <Faq
        title={faq.title}
        questions={
          data.allContentfulApplicationFaqList.edges[0].node
            .faqItems
        }
      />
    </>
  );
};

export const query = graphql`
  query ApplyPage {
    allContentfulApplicationFaqList {
      edges {
        node {
          faqItems {
            answer {
              answer
            }
            question
          }
        }
      }
    }
  }
`;

export default Apply;
